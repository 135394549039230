import styled, { css } from "styled-components";
import media from "constants/media";

export const HeaderStyled = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;

    width: 100%;

    color: var(
        ${({ pageScrolled }) =>
            pageScrolled ? "--colors-dark-blue" : "--colors-white"}
    );

    background: var(
        ${({ pageScrolled }) =>
            pageScrolled ? "--colors-white" : "--colors-dark-blue"}
    );

    box-shadow: ${({ pageScrolled }) =>
        pageScrolled && "0 2px 11px 0 rgba(0,0,0,0.12)"};

    transition: 0.3s all ease-in-out;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1180px;
    height: 60px;
    padding: 0 20px;

    transition: 0.3s height ease-in-out;

    ${media.mdUp`
      height: 90px;

      margin: 0 auto;
    `}
`;

export const Menu = styled.div`
    display: flex;
    align-items: center;

    font-weight: bold;
    font-size: 12px;
    line-height: 30px;

    ${media.mdUp`
      font-size: 14px;
    `}

    svg g {
        transition: 0.3s all ease-in-out;
    }
`;

export const Navigation = styled.nav`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    width: 100%;
    max-width: 310px;

    padding: 14px 20px 50px;

    background: var(--colors-white);

    border-bottom-right-radius: 10px;

    box-shadow: 20px 20px 50px 0 rgba(1, 17, 28, 0.13);

    transform: translateX(-100%);
    visibility: hidden;
    opacity: 0;

    transition: 0.3s all ease-in-out;

    ${({ visible }) =>
        visible &&
        css`
            transform: translateX(0px);
            visibility: visible;
            opacity: 1;
        `}

    ${media.mdUp`
      width: auto;
      max-width: none;

      padding: 28px 96px 60px 20px;

      border-radius: 0 0 20px 0;
    `}

    @media (min-width: 1180px) {
        padding: 28px 96px 60px calc(((100% - 1180px) / 2) + 20px);
    }
`;

export const Items = styled.ul`
    margin: 0;
    padding: 0;
`;

export const Item = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--colors-dark-blue);

    font-weight: bold;
    font-size: 14px;
    line-height: 30px;

    ${({ control }) =>
        control &&
        css`
            justify-content: flex-start;

            font-weight: 700;
            font-size: 14px;
            line-height: 25px;

            img {
                width: 33px;
            }
        `}

    ${({ link }) =>
        link &&
        css`
            a,
            button {
                font-weight: 700;
                font-size: 20px;
                line-height: 50px;

                transition: 0.3s all ease-in-out;
                text-decoration: none;

                ${media.mdUp`
                    font-size: 25px;
                    line-height: 70px;
                `}

                &:hover, &:focus {
                    color: var(--colors-bright-orange);
                }
            }

            button {
                padding: 0;

                background: transparent;

                border: 0;
                outline: 0;

                cursor: pointer;
            }
        `}

    &:first-child {
        margin-bottom: 8px;

        ${media.mdUp`
          margin-bottom: 18px;
        `}
    }

    &:last-child {
        margin-top: 16px;
    }

    ${media.mdUp`
      justify-content: flex-start;
    `}
`;

export const Icon = styled.div`
    width: 33px;
`;

export const Logo = styled.a`
    position: absolute;
    left: 50%;

    transform: translateX(-50%) scale(0.6);

    cursor: pointer;

    svg g {
        transition: 0.3s all ease-in-out;
    }

    ${media.mdUp`
      transform: translateX(-50%) scale(0.9);
    `}
`;

export const Buttons = styled.div`
    display: flex;

    a:not(:last-child) {
        margin-right: 20px;
    }
`;
