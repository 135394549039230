/* eslint-disable react/no-danger */
import PropTypes from "prop-types";
import NextHead from "next/head";

export default function Head({ metadata, title, description }) {
    if (!process.browser) {
        return null;
    }

    const url = metadata?.canonical;
    const pageTitle = title ?? metadata?.title;

    return (
        <NextHead>
            <title>{title || pageTitle}</title>
            <meta
                name="description"
                content={description || metadata?.description}
            />
            <meta name="keywords" content={metadata?.keywords} />
            <meta name="application-name" content={title || metadata?.title} />
            <meta
                name="apple-mobile-web-app-title"
                content={title || metadata?.title}
            />
            <meta property="og:site_name" content={title || metadata?.title} />
            <meta property="og:title" content={title || metadata?.title} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={title || metadata?.title} />
            <meta
                property="og:description"
                content={description || metadata?.description}
            />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={metadata?.image} />
            <meta name="twitter:title" content={title || metadata?.title} />
            <meta
                name="twitter:description"
                content={description || metadata?.description}
            />
        </NextHead>
    );
}

Head.propTypes = {
    metadata: PropTypes.shape({
        canonical: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
        keywords: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string
    }),
    title: PropTypes.string,
    description: PropTypes.string
};

Head.defaultProps = {
    metadata: undefined,
    title: undefined,
    description: undefined
};
