import styled, { css } from "styled-components";
import media from "constants/media";

import Text from "components/shared/text/Text";

export const InputStyled = styled.div`
    position: relative;

    width: 100%;
    height: 100%;

    margin-bottom: 28px;

    input[type="number"] {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        margin: 0;
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;
    }
`;

export const LeftIcon = styled.div`
    position: absolute;
    top: ${({ top }) => (top ? `${top}%` : "70%")};
    left: 16px;
    z-index: 7;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;

    transform: translateY(-50%);

    img,
    svg {
        width: 15px;
        max-height: 15px;
    }
`;

export const Field = styled.input`
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;

    padding: 0 20px;

    color: var(--colors-dark-blue);
    font-size: 14px;
    line-height: 25px;

    background-color: ${({ hasError, hasBalance, hasFocus, inverted }) => {
        if (hasError) {
            return "var(--colors-helpers-errorBg)";
        }
        if (hasBalance) {
            return "var(--colors-cardNumber-input-background-success)";
        }
        if (hasFocus) {
            return "var(--colors-white)";
        }
        return inverted ? "var(--colors-white)" : "var(--colors-light-grey)";
    }};
    border: 1px solid
        var(--colors-${({ hasError }) => (hasError ? "red" : "none")});
    border-radius: var(--border-radius-small);
    outline: 0;

    transition: 0.3s all ease-in-out;

    &::placeholder {
        color: var(--colors-dark-blue);

        opacity: 0.5;
    }

    ${({ hasLeftIcon }) =>
        hasLeftIcon &&
        css`
            padding-left: 55px;
        `}

    ${({ hasRightIcon }) =>
        hasRightIcon &&
        css`
            padding-right: 55px;
        `}


    ${media.mdUp`
        height: 50px;
    `}

    &:focus {
        background-color: var(--colors-white);
        box-shadow: 0 3px 10px 0 var(--colors-box-shadow);
    }
`;

export const LabelText = styled(Text)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    font-weight: bold;
`;

export const HiddenLabelText = styled.span`
    position: absolute;
    left: 100%;

    width: 0;
    height: 0;

    overflow: hidden;

    opacity: 0;
`;

export const Label = styled.label`
    position: relative;

    display: block;

    button {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

export const RightIcon = styled.div`
    position: absolute;
    top: 71%;
    right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;

    transform: translateY(-50%);

    img,
    svg {
        width: 15px;
        max-height: 15px;
    }
`;

export const Error = styled.p`
    position: absolute;
    bottom: -4px;
    left: ${({ errorMessageWidth }) => (errorMessageWidth ? "50%" : "0")};

    width: ${({ errorMessageWidth }) => errorMessageWidth || "100%"};

    color: var(--colors-error);
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;

    transform: translate(
        ${({ errorMessageWidth }) => (errorMessageWidth ? "-50% " : "0")},
        100%
    );
`;
