import { forwardRef } from "react";
import PropTypes from "prop-types";

import {
    InputStyled,
    LeftIcon,
    LabelText,
    HiddenLabelText,
    Field,
    Label,
    RightIcon,
    Error
} from "./Input.styled";

const Input = forwardRef(
    (
        {
            leftIcon,
            rightIcon,
            errorMessage,
            errorMessageWidth,
            label,
            hideLabelText,
            leftIconTop,
            inverted,
            ...props
        },
        ref
    ) => (
        <InputStyled hasError={!!errorMessage}>
            {leftIcon && <LeftIcon top={leftIconTop}>{leftIcon}</LeftIcon>}
            <Label>
                {hideLabelText ? (
                    <HiddenLabelText>{label}</HiddenLabelText>
                ) : (
                    label && <LabelText element="span">{label}</LabelText>
                )}

                <Field
                    hasLeftIcon={!!leftIcon}
                    hasRightIcon={!!rightIcon}
                    hasError={!!errorMessage}
                    inverted={inverted}
                    ref={ref}
                    {...props}
                />
            </Label>

            {rightIcon && <RightIcon>{rightIcon}</RightIcon>}
            {errorMessage && (
                <Error errorMessageWidth={errorMessageWidth}>
                    {errorMessage}
                </Error>
            )}
        </InputStyled>
    )
);

Input.propTypes = {
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    errorMessage: PropTypes.string,
    errorMessageWidth: PropTypes.string,
    label: PropTypes.string,
    hideLabelText: PropTypes.bool,
    className: PropTypes.string,
    leftIconTop: PropTypes.number,
    inverted: PropTypes.bool
};

Input.defaultProps = {
    leftIcon: undefined,
    rightIcon: undefined,
    errorMessage: undefined,
    errorMessageWidth: undefined,
    label: undefined,
    hideLabelText: false,
    className: "",
    leftIconTop: undefined,
    inverted: false
};

export default Input;
