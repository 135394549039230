import PropTypes from "prop-types";

import { Span, Paragraph } from "./Text.styled";

export default function Text({
    element,
    children,
    className,
    leftAlignedMobile,
    leftAlignedDesktop
}) {
    if (element === "span") {
        return (
            <Span
                className={className}
                leftAlignedMobile={leftAlignedMobile}
                leftAlignedDesktop={leftAlignedDesktop}
            >
                {children}
            </Span>
        );
    }

    return (
        <Paragraph
            className={className}
            leftAlignedMobile={leftAlignedMobile}
            leftAlignedDesktop={leftAlignedDesktop}
        >
            {children}
        </Paragraph>
    );
}

Text.propTypes = {
    element: PropTypes.oneOf(["p", "span"]),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    leftAlignedMobile: PropTypes.bool,
    leftAlignedDesktop: PropTypes.bool
};

Text.defaultProps = {
    element: "p",
    className: "",
    leftAlignedMobile: false,
    leftAlignedDesktop: false
};
