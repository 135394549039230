import styled from "styled-components";
import media from "constants/media";

export const Span = styled.span`
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0;
    text-align: ${({ leftAlignedMobile }) =>
        leftAlignedMobile ? "left" : "center"};

    ${media.mdUp`
        font-size: 14px;
        letter-spacing: 0;
        line-height: 30px;
        text-align: ${({ leftAlignedDesktop }) =>
            leftAlignedDesktop ? "left" : "center"};
    `}
`;

export const Paragraph = styled.p`
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0;
    text-align: ${({ leftAlignedMobile }) =>
        leftAlignedMobile ? "left" : "center"};

    ${media.mdUp`
        font-size: 14px;
        letter-spacing: 0;
        line-height: 30px;
        text-align: ${({ leftAlignedDesktop }) =>
            leftAlignedDesktop ? "left" : "center"};  
    `}
`;
